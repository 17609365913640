import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useLocation } from "@reach/router"
import Layout from '../components/Layout/layout'
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import FullWidthTitle from '../components/FullWidthTitle/full-width-title'
import BlogPostRichText from '../components/BlogPostRichText/blog-post-rich-text'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import SeoHelmet from '../components/SeoHelmet/seo-helmet'
import PageTitle from '../components/PageTitle/PageTitle'
import SectionTitle from '../components/SectionTitle/section-title'
import ServiceEndModule from '../components/ServiceEndModule/ServiceEndModule'
import RelatedPosts from '../components/RelatedPosts/RelatedPosts'
import BlogNewsLetter from '../components/BlogPageNewsLetter/BlogPageNewsLetter'
import BlogShare from '../components/BlogShare/BlogShare'
import DarkButton from '../components/buttons/DarkButton/dark-button'
import '../templates/blog-post-page.scss';

export const quert = graphql`
    query($slug: String!) {
        post: contentfulBlogPost(slug: {eq: $slug}) { 
            id
            postTitle
            slug
            endCtaTitle
            endCtaDescription {
              raw
            }
            endCtaLabel
            endCtaUrl
            hasEndOfBlogPageButton
            endOfPageButtonTitleTwo
            endOfPageButtonTitleOne
            endOfPageButtonLinkOne {
              ... on ContentfulUrlAddresses {
                linkUrlAddress
            }
            }
            endOfPageButtonLinkTwo {
              ... on ContentfulUrlAddresses {
                linkUrlAddress
            }
            }
            sidebarCta1 {
              ... on ContentfulUrlAddresses {
                linkName
                linkUrlAddress
              }
            }
            sidebarCta2 {
              ... on ContentfulUrlAddresses {
                linkName
                linkUrlAddress
              }
            }
            postContent {
              raw
              references {
                ... on ContentfulAsset {
                    contentful_id
                    __typename
                    gatsbyImageData(
                        aspectRatio: 1.5
                        cropFocus: CENTER
                        layout: CONSTRAINED
                        width: 1000
                        placeholder: BLURRED
                      )
                      title
                    }
                }
            }
            postDate
            postMainImage {
                gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                  title
            }
            author {
                authorDisplayName
                linkedInUrl
                authorImage {
                    gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: CENTER
                    layout: CONSTRAINED
                    width: 150
                    placeholder: BLURRED
                    )
                    title
                }
            }
            seo {
                metaTitle
                metaDescription {
                  metaDescription
                }
              }
              relatedPosts {
                postTitle
                postDate
                slug
                postMainImage {
                  id
                  gatsbyImageData(
                    aspectRatio: 1.5
                    cropFocus: BOTTOM
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            
          }
          blogPosts: allContentfulBlogPost (filter: {slug: {ne: "sample-blog-post-do-not-delete"}}) {
            edges {
                node {
                    postTitle
                    postDate
                    slug 
                    sorting
                    category {
                        slug
                        title
                        sorting
                    }
                    featured
                    postMainImage {
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            layout: CONSTRAINED
                            width: 600
                            placeholder: BLURRED
                        )
                        title
                        }
                        postShortDescription {
                        postShortDescription
                    }
                }
            }
          }
          siteSettings: allContentfulSiteGeneralSettings(
            filter: {siteTitle: {eq: "25 Spaces Real Estate"}}
          ) {
              edges {
              node {
                  googleReviewRating
                  googleReviewTotal
              }
              }
          }
    }
`

const BlogPostPage = (props) => {
  const { pathname } = useLocation();
  const { data: { post, blogPosts, siteSettings } } = props;
  var siteUrl = process.env.GATSBY_SITE_URL;
  var blogurl = siteUrl + pathname;
  let featuredList = blogPosts.edges.filter((r) => r.node.featured == true);
  let newsArticles = blogPosts.edges.filter((r) => (r.node.category && r.node.category?.slug == "blogs" && r.node.postDate != null));
  //console.log('testtestss', props)
  return (
    <div className='blog-details-section'>
      <Layout>
        <Helmet>
          <script type="application/ld+json">{`{ 
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${blogurl}"
              },
              "headline": "${post?.seo?.metaTitle}",
              "description": "${post?.seo?.metaDescription?.metaDescription}",
              "image": "${post?.postMainImage?.gatsbyImageData.images.fallback.src}",  
              "author": {
                "@type": "Person",
                "name": "Tamara Shinnawie",
                "url": "https://www.linkedin.com/in/tamara-shinnawie-bb728735/"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "25 Spaces Real Estate",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://25spaces.com/static/logo-color@2x-f0ff025386e371872e73e10eae6a8726.png"
                }
              },
              "datePublished": "${post?.postDate}",
              "dateModified": "${post?.postDate}"                      
                  
                  }`}
          </script>
        </Helmet>
        <SeoHelmet
          metaTitle={post.seo.metaTitle}
          metaDescription={post.seo.metaDescription.metaDescription}
          ogimage={post?.postMainImage}
        ></SeoHelmet>

        <GeneralPageSubHeader
          image={post.postMainImage}
          imageAlt={post?.postTitle}
          breadcrumb="Home / Blog / "
          pageName={post.postTitle}
        ></GeneralPageSubHeader>
        <div className='blog-full-width-section'>

          <div className="blog-detail-content">
            <PageTitle
              className="page-title-section"
              title={post.postTitle}
              linkedInURL={post?.author?.linkedInUrl}
              // authorName={post?.author?.authorDisplayName}
              // authorImage={post?.author?.authorImage}
              isBlogPage={true}
              altName={post?.author?.authorImage?.title}
              publishedDate={post?.postDate}
            ></PageTitle>

            <BlogPostRichText
              richText={post.postContent}
              hasBlogEndCta={post.hasEndOfBlogPageButton}
              endOfPageButtonTitleOne={post.endOfPageButtonTitleOne}
              endOfPageButtonTitleTwo={post.endOfPageButtonTitleTwo}
              endOfPageButtonLinkOne={post.endOfPageButtonLinkOne}
              endOfPageButtonLinkTwo={post.endOfPageButtonLinkTwo}
            ></BlogPostRichText>
            <BlogShare />
          </div>
          <div className="blog-sidebar desktop">
            {post.sidebarCta1 || post.sidebarCta2 ?
              <div className='blog-cta-section'>
                {post.sidebarCta1 &&
                  <DarkButton
                    href={post.sidebarCta1.linkUrlAddress}
                    buttonText={post.sidebarCta1.linkName}
                  ></DarkButton>
                }

                {post.sidebarCta2 &&
                  <DarkButton
                    href={post.sidebarCta2.linkUrlAddress}
                    buttonText={post.sidebarCta2.linkName}
                  ></DarkButton>
                }

              </div> : ''}
            {featuredList && featuredList.length > 0 &&
              <div className='featured-list'>
                <SectionTitle
                  sectionNumber=""
                  sectionTitle={"Featured Posts"}
                  sectionTitleSpan={null}
                ></SectionTitle>
                <div className='datalist'>
                  {featuredList.map((n) => {
                    return (
                      <>
                        <div className='list'>
                          <GatsbyImage image={getImage(n.node.postMainImage)} alt={n.node.postMainImage.title} />

                          <Link to={`/blog/${n.node.slug}/`}>{n.node.postTitle}</Link>
                        </div>
                        <span className='hr'></span>
                      </>

                    )
                  })
                  }
                </div>
              </div>
            }
            {newsArticles && newsArticles.length > 0 &&
              <div className='featured-list'>
                <SectionTitle
                  sectionNumber=""
                  sectionTitle={"News Article"}
                  sectionTitleSpan={null}
                ></SectionTitle>
                <div className='datalist'>
                  {newsArticles.map((n) => {
                    const d = new Date(n.node?.postDate)
                    var date = d.toLocaleDateString("en-UK", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    });
                    return (
                      <>
                        <div className='list'>
                          <GatsbyImage image={getImage(n.node.postMainImage)} alt={n.node.postMainImage.title} />
                          <div className='withdate'>
                            <Link to={`/blog/${n.node.slug}/`}>{n.node.postTitle}</Link>
                            <p className='blog-published-date'>{date}</p>
                          </div>


                        </div>
                        <span className='hr'></span>
                      </>

                    )
                  })
                  }
                </div>
              </div>
            }
            <div className='home-reviews-section'>
              <SectionTitle
                sectionNumber=""
                sectionTitle={"WHAT OUR CLIENTS ARE SAYING"}
                sectionTitleSpan={null}
              ></SectionTitle>
              <a href='https://www.google.com/search?q=25+spaces+real+estate&rlz=1C5CHFA_enFR1015FR1015&oq=25+spaces+&aqs=chrome.0.0i355i512j46i175i199i512j69i57j0i512j0i10i22i30j69i60l2j69i61.8433j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3e45c4b871e3d2fd:0xf54afa7d6fa0189,1,,,'>

                <StaticImage
                  src={"../../images/google-review-logo.svg"}
                  alt="Google Review Image"
                  placeholder="blurred"
                />
              </a>
              <p><strong>{siteSettings.edges[0].node.googleReviewRating}/5</strong> Rating from {siteSettings.edges[0].node.googleReviewTotal} customer Reviews</p>
            </div>

          </div>
        </div>
        <BlogNewsLetter></BlogNewsLetter>

        <ServiceEndModule
          Title={post?.endCtaTitle}
          buttonText={post?.endCtaLabel}
          href={post?.endCtaUrl}
          description={post?.endCtaDescription}
          isBlogPage={true}

        >
        </ServiceEndModule>
        <RelatedPosts
          RelatedPosts={post.relatedPosts}
        >
        </RelatedPosts>

      </Layout>
    </div>
  )
}

export default BlogPostPage